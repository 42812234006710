import { Box, Skeleton, Step, StepContent } from '@mui/material';
import React from 'react';

import commonStyles from '../commonStyles';
import { playersPerPage } from 'components/pages/MaturationDataCollection/pageHelpers';
import CustomStepper from 'components/shared/Stepper/CustomStepper';

const TrialLogSkeleton = () => (
  <Box sx={commonStyles.mainBox}>
    <CustomStepper>
      {Array.from({ length: playersPerPage }).map((_, index) => (
        <Step key={index} expanded={true}>
          <Skeleton sx={commonStyles.avatar} variant="circular" />

          <StepContent
            sx={{
              ...commonStyles.stepContent,
              borderLeft:
                index < playersPerPage - 1 ? '1px dashed #DCDCDC' : 'none',
            }}
          >
            <Skeleton sx={commonStyles.name} variant="text" />

            <Skeleton sx={commonStyles.skill} variant="text" />

            <Skeleton sx={commonStyles.score} variant="text" />

            <Skeleton sx={commonStyles.date} variant="text" />
          </StepContent>
        </Step>
      ))}
    </CustomStepper>
  </Box>
);

export default TrialLogSkeleton;
