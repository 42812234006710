import { Box, SxProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { castActiveState } from 'state/atoms';

interface Props {
  pageTitle?: ReactNode;
  readonly children?: ReactNode;
  readonly headerRight?: ReactNode;
  readonly sx?: SxProps;
  readonly headerStyles?: SxProps;
}

const customStyles = {
  mainContainer: {
    flex: 1,
    mr: 0.5,
    p: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    ['@media (max-width: 991px)']: {
      ml: 0,
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 10,
  },
  pageTitle: {
    mb: 0,
  },
};

const PageContainer = ({
  pageTitle,
  headerRight,
  children,
  sx,
  headerStyles,
}: Props) => {
  const isCastActive = useRecoilValue(castActiveState);

  const castStyles = {
    mt: isCastActive ? 0 : 17,
    ml: isCastActive ? 0 : 17.5,
  };

  return (
    <Box
      sx={
        sx
          ? { ...castStyles, ...customStyles.mainContainer, ...sx }
          : { ...castStyles, ...customStyles.mainContainer }
      }
    >
      {(pageTitle || headerRight) && (
        <Box sx={{ ...customStyles.headerContainer, ...headerStyles }}>
          <Typography variant="h1" sx={customStyles.pageTitle}>
            {pageTitle}
          </Typography>

          {headerRight}
        </Box>
      )}

      {children}
    </Box>
  );
};

export default PageContainer;
