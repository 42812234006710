import { Stepper, StepperProps } from '@mui/material';
import { ReactNode } from 'react';

const customStyles = {
  stepperWidgets: {
    mt: 1.7,
    px: 2,
    position: 'relative',
    '& .MuiStepConnector-vertical': {
      display: 'none',
    },
  },
};

interface Props extends StepperProps {
  children: ReactNode;
}

const CustomStepper = (props: Props) => (
  <Stepper sx={customStyles.stepperWidgets} orientation="vertical" {...props}>
    {props.children}
  </Stepper>
);

export default CustomStepper;
