import { Skeleton, Stack, Typography } from '@mui/material';

import commonStyles from '../commonStyles';
import { mainThemeBoldFontFamily } from 'components/MUI/typography';

interface Props {
  label: string;
  count: number;
  isDataFetching: boolean;
}

const AnalysisSessionSection = ({ label, count, isDataFetching }: Props) => (
  <Stack sx={commonStyles.content}>
    {isDataFetching ? (
      <Skeleton sx={commonStyles.smallFont} variant="text" />
    ) : (
      <Typography mb={1}>{label}</Typography>
    )}

    {isDataFetching ? (
      <Skeleton sx={commonStyles.mediumFont} variant="text" />
    ) : (
      <Typography
        fontSize={28}
        fontFamily={mainThemeBoldFontFamily}
        color={'black'}
      >
        {count}
      </Typography>
    )}
  </Stack>
);

export default AnalysisSessionSection;
