const commonStyles = {
  headerTextField: {
    width: 250,
    height: 35,
  },
  content: {
    height: '100%',
    width: '100%',
  },
  resultSelect: {
    minWidth: 100,
    maxWidth: '10%',
  },
  infoIcon: {
    fontSize: 32,
    mb: 1,
    color: 'black',
  },
  metricSelect: {
    width: 200,
  },
  smallFont: {
    fontSize: 14,
  },
  mediumFont: {
    fontSize: 24,
  },
  largeFont: {
    fontSize: 30,
  },
  attributeBox: {
    backgroundColor: '#24D86C',
    height: 45,
    width: 45,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartIcon: {
    borderBottom: '2px solid white',
    color: 'white',
  },
  attributeSkeleton: {
    borderRadius: 2,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  nameSkeleton: {
    fontSize: 24,
    width: 200,
  },
  link: {
    textDecoration: 'none',
    color: 'typography.secondary',
  },
  tableMargin: {
    mt: 4,
  },
  attributeColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  metricTableLink: {
    textDecoration: 'none',
    color: 'typography.secondary',
    justifyContent: 'center',
  },
  searchIcon: {
    color: 'black',
  },
  attributeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: '#F6F6F6',
    borderRadius: 5,
    p: 4,
  },
  dropDownSkeleton: {
    borderRadius: 2,
  },
  dropDownBox: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: {
      xs: 2,
      md: 0,
    },
    justifyContent: {
      xs: 'start',
      md: 'end',
    },
    cursor: 'pointer',
    gap: 4,
    maxWidth: {
      md: '70vw',
    },
  },
  headerStyles: {
    alignItems: 'start',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  divider: {
    borderColor: '#DCDCDC',
    borderStyle: 'dashed',
  },
  trendingUpIcon: {
    height: 18,
    width: 18,
    color: 'red',
  },
  dataLog: {
    height: 400,
    pt: 2,
    overflow: 'hidden',
    ':hover': {
      overflowY: 'auto',
    },
  },
  name: {
    width: '10vW',
    mt: -12,
  },
  score: {
    width: '8vW',
  },
  skill: {
    width: '4vW',
  },
  date: {
    width: '12vW',
  },
  stepContent: {
    top: -10,
    pb: 8,
    pl: 10,
    ml: 6,
  },
  mainBox: {
    ml: -2,
    mt: -2,
  },
  autoComplete: {
    width: {
      xs: '75vw',
      sm: '40vw',
      md: '20vw',
      lg: '15vw',
      xl: '14vw',
    },
    '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
      background: '#F6F6F6',
      minHeight: 39,
      height: 'auto',
    },
  },
  dropDown: {
    width: {
      xs: '75vw',
      sm: '40vw',
      md: '20vw',
      lg: '15vw',
      xl: '14vw',
    },
  },
};

export default commonStyles;
