import { MenuItem } from '@mui/material';
import React from 'react';

interface Props {
  customText?: React.ReactNode;
}

const NoOptionMenu = ({ customText }: Props) => (
  <MenuItem disabled>{customText ?? 'No options'}</MenuItem>
);

export default NoOptionMenu;
