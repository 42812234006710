import { Autocomplete, Chip, TextField } from '@mui/material';
import { Dispatch } from 'react';

import commonStyles from '../commonStyles';
import { DropDownValues } from '../types';
import FormFieldContainer from 'components/shared/Containers/FormFieldContainer';
import { InferTRPCOutputTypes } from 'helpers/trpc';

type PositionGroup =
  InferTRPCOutputTypes['shared']['getPositionGroups']['positionGroups'][number];

interface Props {
  positionGroups: PositionGroup[];
  positionGroupIds: string[];
  setDropDownValues: Dispatch<React.SetStateAction<DropDownValues>>;
}

const PositionGroupsAutocomplete = ({
  positionGroups,
  positionGroupIds,
  setDropDownValues,
}: Props) => {
  return (
    <FormFieldContainer label="Position Groups">
      <Autocomplete
        multiple
        disableClearable
        disableCloseOnSelect
        filterSelectedOptions
        options={positionGroups ?? []}
        getOptionLabel={(option) => option.name}
        sx={commonStyles.autoComplete}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="No options"
        onChange={(_, values) =>
          setDropDownValues((previous: any) => ({
            ...previous,
            positionIds: [],
            positionGroupIds: values.map((group) => group.id),
          }))
        }
        renderInput={(params) => {
          const showAllOption = positionGroupIds?.length === 0;

          if (showAllOption) {
            params.InputProps.startAdornment = (
              <Chip label="All Position Groups" size="small" color="primary" />
            );
          }

          return <TextField {...params} label="Squad" />;
        }}
      />
    </FormFieldContainer>
  );
};

export default PositionGroupsAutocomplete;
