import { Autocomplete, Chip, TextField } from '@mui/material';
import { Dispatch, useMemo } from 'react';

import commonStyles from '../commonStyles';
import { defaultString } from '../pageHelpers';
import { DropDownValues } from '../types';
import FormFieldContainer from 'components/shared/Containers/FormFieldContainer';
import { AllPositionGroupOption } from 'helpers/globalConstants';
import { V2PositionsQueriesListModelsPositionModel } from 'orval/calceyAPI/reactQuery';
import { PositionGroup } from 'server/routers/shared/types';

interface Props {
  positions: V2PositionsQueriesListModelsPositionModel[];
  positionGroups: PositionGroup[];
  selectedPositionGroupIds: string[];
  positionIds: number[];
  setDropDownValues: Dispatch<React.SetStateAction<DropDownValues>>;
}

const PositionsAutocomplete = ({
  positions,
  positionGroups,
  selectedPositionGroupIds,
  positionIds,
  setDropDownValues,
}: Props) => {
  const selectedPositions = useMemo(() => {
    const positionList = positions.filter((position) =>
      positionIds.includes(position.id!)
    );

    return positionList;
  }, [positionIds, positions]);

  const filteredPositions = useMemo(() => {
    if (selectedPositionGroupIds.length === 0) {
      return positions;
    }

    const selectedPositionGroups = positionGroups.filter((group) =>
      selectedPositionGroupIds.includes(group.id)
    );

    const selectedPositions = selectedPositionGroups
      .concat(AllPositionGroupOption)
      .filter((group) => positionGroups.includes(group))
      .flatMap((group) => group.positionIds);

    const filteredPositions = positions.filter((position) => {
      return selectedPositions?.includes(position.id!);
    });

    return filteredPositions;
  }, [positionGroups, positions, selectedPositionGroupIds]);

  return (
    <FormFieldContainer label="Positions">
      <Autocomplete
        multiple
        disableClearable
        disableCloseOnSelect
        filterSelectedOptions
        options={filteredPositions}
        value={selectedPositions}
        getOptionLabel={(option) => option.name ?? defaultString}
        sx={commonStyles.autoComplete}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="No options"
        onChange={(_, values) =>
          setDropDownValues((previous: any) => ({
            ...previous,
            positionIds: values.map((position) => position?.id as number),
          }))
        }
        renderInput={(params) => {
          const showAllOption = positionIds.length === 0;

          if (showAllOption) {
            params.InputProps.startAdornment = (
              <Chip label="All positions" size="small" color="primary" />
            );
          }

          return <TextField {...params} label="Position" />;
        }}
      />
    </FormFieldContainer>
  );
};

export default PositionsAutocomplete;
