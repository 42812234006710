import { Measurement, Session, Team } from './types';

export const defaultString = '';
export const defaultValue = 0;

type SessionType = 'Lab session' | 'Training session';

export const getCombinedSessions = (sessions: Session) => {
  if (!sessions) return [];

  const combinedSessions = [
    ...sessions?.labSessions.map((session) => ({
      id: session.id,
      sessionId: session.sessionId,
      type: 'Lab session' as SessionType,
      name: session.name,
      eplAffiliationId: session.eplAffiliationId,
    })),
    ...sessions?.trainingSessions.map((session) => ({
      id: session.id,
      sessionId: session.sessionId,
      type: 'Training session' as SessionType,
      name: session.name,
      eplAffiliationId: undefined,
    })),
  ];

  return combinedSessions;
};

export const getSession = (sessions: Session, sessionId: string) => {
  const combinedSessions = getCombinedSessions(sessions);

  const session = combinedSessions.find((session) => session.id === sessionId);

  return session;
};

export const defaultDropDownValues = {
  positionGroupIds: [] as string[],
  positionIds: [] as number[],
  teamId: null as number | null,
  sessionId: null as string | null,
  measurementId: null as number | null,
};

export const defaultDropDownOptions = {
  sessions: null as Session | null,
  teams: [] as Team[],
  measurements: [] as Measurement[],
};
