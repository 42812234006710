import { MenuItem, Select } from '@mui/material';
import { Dispatch, useEffect, useMemo } from 'react';

import commonStyles from '../commonStyles';
import { getCombinedSessions } from '../pageHelpers';
import { DropDownValues, Session } from '../types';
import FormFieldContainer from 'components/shared/Containers/FormFieldContainer';
import NoOptionMenu from 'components/shared/NoOptionMenu';

interface Props {
  sessions: Session;
  sessionId: string;
  setDropDownValues: Dispatch<React.SetStateAction<DropDownValues>>;
}

const SessionDropDown = ({ sessions, sessionId, setDropDownValues }: Props) => {
  const combinedSessions = useMemo(() => {
    return getCombinedSessions(sessions);
  }, [sessions]);

  useEffect(() => {
    if (combinedSessions.length === 0) {
      setDropDownValues((previous) => ({
        ...previous,
        sessionId: null,
      }));

      return;
    }

    const defaultSessionId = combinedSessions[0].id;

    if (defaultSessionId) {
      setDropDownValues((previous) => ({
        ...previous,
        sessionId: defaultSessionId,
      }));
    }
  }, [setDropDownValues, sessions, combinedSessions]);

  return (
    <FormFieldContainer label="Session">
      <Select
        label="Session"
        size="small"
        sx={commonStyles.dropDown}
        value={sessionId}
        onChange={(event) =>
          setDropDownValues((previous) => ({
            ...previous,
            sessionId: event.target.value,
          }))
        }
      >
        {combinedSessions.length > 0 ? (
          combinedSessions.map((group, index) => (
            <MenuItem key={index} value={group.id}>
              {group.name}
            </MenuItem>
          ))
        ) : (
          <NoOptionMenu customText="No sessions" />
        )}
      </Select>
    </FormFieldContainer>
  );
};

export default SessionDropDown;
