import { Avatar, Stack } from '@mui/material';

const customStyles = {
  avatar: {
    position: 'relative',
    right: 8,
    top: 4,
    background: 'transparent',
    zIndex: 1,
  },
  detailStack: {
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: -2,
  },
};

type AvatarVariant = 'circular' | 'rounded' | 'square';

interface Props {
  imageSource: string;
  variant?: AvatarVariant;
}

const CustomStepAvatar = ({ imageSource, variant }: Props) => (
  <Stack direction="row" sx={customStyles.detailStack}>
    <Stack direction="row">
      <Avatar src={imageSource} sx={customStyles.avatar} variant={variant} />
    </Stack>
  </Stack>
);

export default CustomStepAvatar;
