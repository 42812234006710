import { StepContent, StepContentProps } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends StepContentProps {
  children: ReactNode;
  steps: number;
  currentStep: number;
}

const CustomStepContent = (props: Props) => {
  const customStyles = {
    stepContent: {
      borderLeft:
        props.currentStep < props.steps - 1 ? '1px dashed #DCDCDC' : 'none',
      mt: -11,
      pb: 8,
      pl: 10,
      ml: 4,
      mb: 1.5,
      pt: 1,
    },
  };

  return (
    <StepContent sx={customStyles.stepContent} {...props}>
      {props.children}
    </StepContent>
  );
};

export default CustomStepContent;
