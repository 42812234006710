export const playerPositions = [
  'All',
  'Attacking Midfield',
  'Central Midfield',
  'Centre Back',
  'Centre Forward',
  'Defensive Midfield',
  'Goalkeeper',
  'Left Back',
  'Left Midfield',
  'Left Wing',
  'Left Wing Back',
  'Right Back',
  'Right Midfield',
  'Right Wing',
  'Right Wing Back',
  'Striker',
  'Sweeper',
  'Wing Back',
  'Referee',
];

export const preferredFootOptions = [
  'NotSelected',
  'Right',
  'Left',
  'Both',
] as const;

export const genderSelections = ['All', 'Male', 'Female'] as const;

export const gender = ['male', 'female'] as const;
export const trialTypes = ['physical', 'cognitive'] as const;
export const genderOptions = ['All', 'Male', 'Female'] as const;

export const AllPositionGroupOption = {
  name: 'All Position Groups',
  id: '0',
  positionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
};

export const AllPositionOption = {
  id: 0,
  name: 'All Positions',
};
