import { MenuItem, Select } from '@mui/material';
import { Dispatch, useEffect } from 'react';

import commonStyles from '../commonStyles';
import { DropDownValues, Team } from '../types';
import FormFieldContainer from 'components/shared/Containers/FormFieldContainer';
import NoOptionMenu from 'components/shared/NoOptionMenu';

interface Props {
  clubTeams: Team[];
  teamId: number;
  setDropDownValues: Dispatch<React.SetStateAction<DropDownValues>>;
}

const ClubTeamDropDown = ({ clubTeams, teamId, setDropDownValues }: Props) => {
  useEffect(() => {
    if (clubTeams.length === 0) {
      setDropDownValues((previous) => ({
        ...previous,
        teamId: null,
      }));

      return;
    }

    const defaultClubTeamId = clubTeams[0]?.id;

    if (defaultClubTeamId) {
      setDropDownValues((previous) => ({
        ...previous,
        teamId: defaultClubTeamId,
      }));
    }
  }, [clubTeams, setDropDownValues]);

  return (
    <FormFieldContainer label="Team">
      <Select
        label="Club Team"
        size="small"
        sx={commonStyles.dropDown}
        value={teamId}
        onChange={(event) =>
          setDropDownValues((previous) => ({
            ...previous,
            teamId: event.target.value as number,
          }))
        }
      >
        {clubTeams.length > 0 ? (
          clubTeams.map((team, index) => (
            <MenuItem key={index} value={team.id}>
              {team.name}
            </MenuItem>
          ))
        ) : (
          <NoOptionMenu customText="No teams" />
        )}
      </Select>
    </FormFieldContainer>
  );
};

export default ClubTeamDropDown;
