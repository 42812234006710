import { Box, Grid, Typography } from '@mui/material';

import commonStyles from '../commonStyles';
import { AcademyAnalysis } from '../types';
import TrialLogSection from './TrialLogSection';
import TrialLogSkeleton from './TrialLogSkeleton';
import ContentContainer from 'components/shared/ContentContainer';

interface Props {
  isHome: boolean;
  isDataFetching: boolean;
  academyAnalysis: AcademyAnalysis | undefined;
}

const DataLog = ({ isHome, isDataFetching, academyAnalysis }: Props) => (
  <Grid item xs={12} sm={12} md={isHome ? 6 : 12} lg={3} xl={3}>
    <ContentContainer containerTitle="New Data Log" sx={commonStyles.content}>
      <Box sx={commonStyles.dataLog}>
        {isDataFetching ? (
          <TrialLogSkeleton />
        ) : (
          <>
            {academyAnalysis?.newTrialLog.length === 0 ? (
              <Typography fontSize={16}>No new data log available</Typography>
            ) : (
              <>
                {academyAnalysis?.newTrialLog &&
                  academyAnalysis?.newTrialLog && (
                    <TrialLogSection trials={academyAnalysis.newTrialLog} />
                  )}
              </>
            )}
          </>
        )}
      </Box>
    </ContentContainer>
  </Grid>
);

export default DataLog;
