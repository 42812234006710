import { Box, Stack, SxProps } from '@mui/material';
import React from 'react';

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
  wrapperStyle?: SxProps;
}

const customStyles = {
  container: {
    fontSize: '14px',
    color: 'typography.secondary',
  },
};

const FormFieldContainer = ({ label, wrapperStyle, children }: Props) => (
  <Stack sx={wrapperStyle} spacing={1}>
    <Box sx={customStyles.container}>{label}</Box>

    <Box>{children}</Box>
  </Stack>
);

export default FormFieldContainer;
