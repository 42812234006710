import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const customStyles = {
  completionHeading: {
    width: 250,
    height: 35,
  },
  completionHeadingValue: {
    width: 20,
    height: 35,
  },
  completionSubHeading: {
    width: '90%',
    height: 35,
    borderRadius: 4,
  },
  completionSubHeadingValue: {
    width: '8%',
    height: 35,
  },
};

const AnalysisSessionCompletionSkeleton = () => (
  <Stack>
    <Stack p={4} spacing={4}>
      <Stack direction="row" alignItems="flex-end" gap={2}>
        <Skeleton sx={customStyles.completionHeading} variant="text" />

        <Skeleton sx={customStyles.completionHeadingValue} variant="text" />
      </Stack>

      <Stack direction="row" alignItems="flex-end" gap={2}>
        <Skeleton sx={customStyles.completionSubHeading} variant="text" />

        <Skeleton sx={customStyles.completionSubHeadingValue} variant="text" />
      </Stack>
    </Stack>
  </Stack>
);

export default AnalysisSessionCompletionSkeleton;
