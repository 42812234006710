import { Box, Skeleton, Stack } from '@mui/material';
import { Dispatch, useCallback, useEffect, useMemo, useRef } from 'react';

import commonStyles from '../commonStyles';
import { DropDownOptions, DropDownValues } from '../types';
import ClubTeamDropDown from './ClubTeamDropDown';
import PositionGroupsAutocomplete from './PositionGroupsAutocomplete';
import PositionsAutocomplete from './PositionsAutocomplete';
import SessionDropDown from './SessionDropDown';
import { trpc } from 'helpers/trpc';
import { useGetApiV2Positions } from 'orval/calceyAPI/reactQuery';

interface Props {
  dropDownValues: DropDownValues;
  setDropDownValues: Dispatch<React.SetStateAction<DropDownValues>>;
  setDropDownOptions: Dispatch<React.SetStateAction<DropDownOptions>>;
}

const DropDownSection = ({
  dropDownValues,
  setDropDownValues,
  setDropDownOptions,
}: Props) => {
  const isInitialFetch = useRef(true);

  const { data: currentUser } = trpc.shared.currentUser.useQuery(undefined, {
    enabled: isInitialFetch.current,
  });

  const {
    data: positionGroups,
    isFetching: isPositionGroupsFetching,
    refetch: refetchPositions,
  } = trpc.shared.getPositionGroups.useQuery(undefined, {
    enabled: isInitialFetch.current,
  });

  const {
    data: playerPositions,
    isFetching: isPositionsFetching,
    refetch: refetchPositionGroups,
  } = useGetApiV2Positions({
    query: { enabled: isInitialFetch.current },
    request: {
      headers: {
        Authorization: `Bearer ${currentUser?.user.accessToken}`,
      },
    },
  });

  const {
    data: teams,
    isFetching: isTeamsFetching,
    refetch: refetchTeams,
  } = trpc.shared.getProClubAcademyTeams.useQuery(undefined, {
    enabled: isInitialFetch.current,
  });

  const {
    data: sessions,
    isFetching: isSessionsFetching,
    refetch: refetchSessions,
  } = trpc.shared.getProClubAcademySession.useQuery(undefined, {
    enabled: isInitialFetch.current,
  });

  const isTrainingSession = useMemo(() => {
    return sessions?.trainingSessions.some(
      (session) => session.id === dropDownValues.sessionId
    );
  }, [dropDownValues.sessionId, sessions?.trainingSessions]);

  const reloadData = useCallback(() => {
    refetchPositions();
    refetchPositionGroups();
    refetchTeams();
    refetchSessions();
  }, [refetchPositionGroups, refetchPositions, refetchSessions, refetchTeams]);

  const isDataFetching =
    isPositionGroupsFetching || isPositionsFetching || isSessionsFetching;

  useEffect(() => {
    reloadData();
  }, [reloadData, currentUser?.club.activeClubId]);

  useEffect(() => {
    const isDataAvailable =
      positionGroups && playerPositions && teams && sessions;

    if (!isDataAvailable) {
      return;
    }

    isInitialFetch.current = false;

    setDropDownOptions((previous) => ({
      ...previous,
      sessions,
      teams: teams.academyTeams,
    }));
  }, [playerPositions, positionGroups, sessions, setDropDownOptions, teams]);

  return (
    <Stack direction="row" spacing={4}>
      <Box sx={commonStyles.dropDownBox}>
        {isDataFetching ? (
          <Skeleton height={70} width={200} />
        ) : (
          <PositionGroupsAutocomplete
            positionGroups={positionGroups?.positionGroups ?? []}
            positionGroupIds={dropDownValues.positionGroupIds}
            setDropDownValues={setDropDownValues}
          />
        )}

        {isDataFetching ? (
          <Skeleton height={70} width={200} />
        ) : (
          <PositionsAutocomplete
            positions={playerPositions?.items ?? []}
            positionGroups={positionGroups?.positionGroups ?? []}
            selectedPositionGroupIds={dropDownValues.positionGroupIds}
            positionIds={dropDownValues.positionIds}
            setDropDownValues={setDropDownValues}
          />
        )}

        {isDataFetching || isTeamsFetching ? (
          <Skeleton height={70} width={200} />
        ) : (
          isTrainingSession && (
            <ClubTeamDropDown
              clubTeams={teams?.academyTeams ?? []}
              teamId={dropDownValues.teamId!}
              setDropDownValues={setDropDownValues}
            />
          )
        )}

        {isDataFetching ? (
          <Skeleton height={70} width={200} />
        ) : (
          <SessionDropDown
            sessions={sessions!}
            sessionId={dropDownValues.sessionId!}
            setDropDownValues={setDropDownValues}
          />
        )}
      </Box>
    </Stack>
  );
};

export default DropDownSection;
