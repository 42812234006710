import { Step, Typography } from '@mui/material';
import { env } from 'env.mjs';
import React from 'react';

import CustomStepAvatar from 'components/shared/Stepper/CustomStepAvatar';
import CustomStepContent from 'components/shared/Stepper/CustomStepContent';
import CustomStepper from 'components/shared/Stepper/CustomStepper';
import getRegionalDate, { monthOptions } from 'helpers/getRegionalDate';
import { InferTRPCOutputTypes } from 'helpers/trpc';

type Trial =
  InferTRPCOutputTypes['academyAnalysis']['getAcademyAnalysisSessionDashboard']['newTrialLog'][number];
interface Props {
  trials: Trial[];
}

const TrialLogSection = ({ trials }: Props) => (
  <CustomStepper>
    {trials.map((trialLog, index) => (
      <Step key={index} expanded={true}>
        <CustomStepAvatar
          imageSource={`${env.NEXT_PUBLIC_CALCEY_S3_BUCKET_URL}/${trialLog.profileImagePath}-thumbnail-250.png`}
        />

        <CustomStepContent currentStep={index} steps={trials.length}>
          <Typography variant="h3" mb={0.5}>
            {trialLog.name}
          </Typography>

          <Typography variant="h3" mb={0.5}>
            {trialLog.drillTitle}
          </Typography>

          <Typography>{`Score: ${trialLog.score.toFixed(2)}`}</Typography>

          <Typography>
            {getRegionalDate(trialLog.date, monthOptions)}
          </Typography>
        </CustomStepContent>
      </Step>
    ))}
  </CustomStepper>
);

export default TrialLogSection;
