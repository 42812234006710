import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  value: number;
  barColor?: string;
  completedColor?: string;
  showValue?: boolean;
}

const ProgressBar = ({
  barColor = '#FFFFFF',
  completedColor = '#24D86C',
  showValue = true,
  value,
}: Props) => {
  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    progressBar: {
      height: '20px',
      width: '100%',
      backgroundColor: barColor,
      borderRadius: '20px',
      p: '4px',
      mr: '16px',
    },
    completedBar: {
      height: '100%',
      width: `${value}%`,
      transition: 'width 1s ease-in-out',
      backgroundColor: completedColor,
      borderRadius: 'inherit',
      display: value === 0 ? 'none' : 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      pr: 0.9,
    },
    circleIcon: { fontSize: 8, color: 'white' },
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.progressBar}>
        <Box sx={styles.completedBar}>
          <CircleIcon sx={styles.circleIcon} />
        </Box>
      </Box>
      {showValue && <Typography variant="h3">{`${value}%`}</Typography>}
    </Box>
  );
};

export default ProgressBar;
