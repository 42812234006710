import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Grid, Stack, Typography } from '@mui/material';

import commonStyles from '../commonStyles';
import AnalysisSessionCompletionSkeleton from './AnalysisSessionCompletionSkeleton';
import { mainThemeBoldFontFamily } from 'components/MUI/typography';
import ContentContainer from 'components/shared/ContentContainer';
import ProgressBar from 'components/shared/ProgressBar';

interface RemainingDrillsData {
  drillsRemaining: number;
  remainingDrillsPercentage: number;
}

interface Props {
  isDataFetching: boolean;
  remainingDrillsData: RemainingDrillsData;
}

const AnalysisSessionCompletion = ({
  isDataFetching,
  remainingDrillsData,
}: Props) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
    <ContentContainer
      containerTitle="Analysis Session Completion"
      sx={commonStyles.content}
    >
      {isDataFetching ? (
        <AnalysisSessionCompletionSkeleton />
      ) : (
        <Stack spacing={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography
              fontSize={28}
              fontFamily={mainThemeBoldFontFamily}
              color={'black'}
            >
              {remainingDrillsData.drillsRemaining}
            </Typography>

            <Typography>Analysis still to do</Typography>

            <TrendingUpIcon sx={commonStyles.trendingUpIcon} />
          </Stack>

          <ProgressBar value={remainingDrillsData.remainingDrillsPercentage} />
        </Stack>
      )}
    </ContentContainer>
  </Grid>
);

export default AnalysisSessionCompletion;
